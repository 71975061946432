import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "addendum/";

// get an addendum
const getAddendum = async (id) => {
  const res = await axios.get(API_URL + id);

  if (res.status === 404 || res.data.data === null) {
    throw new Error("Addendum not found");
  }

  return res.data.data;
};

// sign an addendum
const signAddendum = async (data) => {
  const { id, signature, term } = data;

  const response = await axios.patch(API_URL + "sign/" + id, {
    signature,
    term,
  });
  return response.data;
};

const addendumService = {
  getAddendum,
  signAddendum,
};

export default addendumService;
