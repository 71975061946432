import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addendumService from "./addendum.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  addendum: null,
  addendums: [],
};

//sign an addendum
export const signAddendum = createAsyncThunk(
  "addendums/sign",
  async function (data, thunkAPI) {
    try {
      return await addendumService.signAddendum(data);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an addendum
export const getAddendum = createAsyncThunk(
  "addendums/details",
  async function (id, thunkAPI) {
    try {
      return await addendumService.getAddendum(id);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

const addendumSlice = createSlice({
  name: "addendum",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signAddendum.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signAddendum.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addendum = action.payload;
      })
      .addCase(signAddendum.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAddendum.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAddendum.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addendum = action.payload;
      })
      .addCase(getAddendum.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export default addendumSlice.reducer;

export const { reset } = addendumSlice.actions;
