import React from "react";

export default function Loader() {
  return (
    <div className="flex flex-col justify-center items-center h-[90vh]">
      <div class="newtons-cradle">
        <div class="newtons-cradle__dot"></div>
        <div class="newtons-cradle__dot"></div>
        <div class="newtons-cradle__dot"></div>
        <div class="newtons-cradle__dot"></div>
      </div>
      <p className="text-2xl text-primary">Loading...</p>
    </div>
  );
}
