import { createBrowserRouter } from "react-router-dom";
import ContractHome from "../pages/contract";
import SuccessAgreement from "../pages/contract/success";
import Home from "../pages/home";
import PaymentHome from "../pages/payments";
import SuccessScreen from "../pages/success";
import Completed from "../pages/completed/Completed";
import ErrorScreen from "../pages/error";
import ConfirmScreen from "../pages/payments/ConfirmSubscriptionForAch";
import ContractAmendmentForm from "../pages/contract/addendum";

export const router = createBrowserRouter([
  {
    path: "/:id",
    element: <Home />,
  },
  {
    path: "/contract/:id",
    element: <ContractHome />,
  },
  {
    path: "/contract/addendum/:id",
    element: <ContractAmendmentForm />,
  },
  {
    path: "/contract/:id/success",
    element: <SuccessAgreement />,
  },
  {
    path: "/payment/:id",
    element: <PaymentHome />,
  },
  {
    path: "/payment/:id/confirm",
    element: <ConfirmScreen />,
  },
  {
    path: "/payment/:id/success",
    element: <SuccessScreen />,
  },
  {
    path: "/payment/:id/error",
    element: <ErrorScreen />,
  },
  {
    path: "/payment/:id/completed",
    element: <Completed />,
  },
]);
