import { useState, useEffect } from "react";

const useProratedCharges = (lead) => {
  const [proratedCharges, setProratedCharges] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);

  useEffect(() => {
    if (lead?.billing_date && lead?.package?.amount) {
      const calculateCharges = () => {
        const today = new Date();
        const billingDay = lead.billing_date;

        // Calculate the next billing date
        let nextBillingDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          billingDay
        );
        if (nextBillingDate <= today) {
          nextBillingDate.setMonth(nextBillingDate.getMonth() + 1);
        }

        // Calculate total one-time fees
        const totalOnetimeFees =
          lead.onetime_fees?.reduce(
            (acc, fee) => acc + parseFloat(fee.feeAmount || 0),
            0
          ) || 0;

        // Calculate prorated charges
        const daysInMonth = new Date(
          nextBillingDate.getFullYear(),
          nextBillingDate.getMonth() + 1,
          0
        ).getDate();
        const remainingDays = Math.ceil(
          (nextBillingDate - today) / (1000 * 60 * 60 * 24)
        );
        const dailyRate = lead.package.amount / daysInMonth;

        const proratedAmount = dailyRate * remainingDays;

        // Set state
        setProratedCharges(proratedAmount);
        setTotalCharges(proratedAmount + totalOnetimeFees);
      };

      calculateCharges();
    }
  }, [lead]);

  return { proratedCharges, totalCharges };
};

export default useProratedCharges;
