import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLead } from "../../redux/leads/lead.slices";
import axios from "axios";
import { toast } from "react-toastify";

export default function ConfirmScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const location = useLocation();

  const { isLoading, lead } = useSelector((state) => state.leads);

  useEffect(() => {
    dispatch(getLead(id));
  }, [dispatch, id]);

  const confirmData = async () => {
    if (
      lead &&
      lead.paymentMethod !== "card" &&
      (lead.status === "Payment Awaiting" ||
        lead.status === "ACH Payment Awaiting")
    ) {
      const totalOnetimeFees = lead?.onetime_fees?.reduce(
        (acc, curr) => (acc += parseFloat(curr.feeAmount)),
        0
      );
      const queryParams = new URLSearchParams(location.search);

      const customer = queryParams.get("customer");
      const initial = queryParams.get("initial");

      await axios
        .post(process.env.REACT_APP_API_URL + "stripes/ach/subscribe", {
          status: "Pending agreement",
          lead: lead?._id,
          manager: lead?.manager?._id,
          customer,
          initial_payment: initial,
          lead_name: lead?.firstname + " " + lead?.lastname,
          email: lead?.email,
          priceID: lead?.package?.price_id,
          selected_method: lead?.paymentMethod,
          one_time_fees: totalOnetimeFees,
          contract_length: lead?.contract_length,
          recurring_date: lead?.billing_date,
          name: lead?.package?.name,
          amount: lead?.package?.amount,
          prorated: initial,
        })
        .then(() => {
          toast.success("Confirmed");
          navigate(`/payment/${id}/success`);
        })
        .catch((error) => {
          let msg =
            error?.response?.data?.error?.message?.toString() ||
            error?.response?.data?.message?.toString() ||
            error?.response?.data?.error?.toString();
          toast.error(msg);
        });
    }
  };

  let today = new Date();
  let todayDate = today.getDate();

  if (parseInt(lead?.billing_date) < todayDate) {
    today.setMonth(today.getMonth() + 1);
    today.setDate(parseInt(lead?.billing_date));
  } else {
    today.setDate(lead?.billing_date);
  }

  if (isLoading)
    return <h1 className="text-center w-full my-20">Loading...</h1>;

  return (
    <section className="border min-h-1/2 rounded-md lg:w-1/3 mx-auto m-10">
      <div className="py-5 flex flex-col justify-center items-center rounded-t-md bg-[#0b0b0b]">
        <div>
          <i className="ri-bank-line text-6xl font-thin text-white my-10"></i>
        </div>
        <h1 className="text-white">Bank Details collected</h1>
      </div>
      {isLoading ? (
        <div className="p-6 flex flex-col justify-center">
          Please confirm your subscription
        </div>
      ) : (
        <div className="p-6 flex flex-col justify-center">
          <div className="w-full p-6 mx-auto lg:w-2/3">
            <span className="text-gray-500 my-4">
              Click the button below to confirm your subscription
            </span>
            <button
              className="block my-4 text-center uppercase shadow bg-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
              onClick={confirmData}
            >
              Confirm Subscription
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
