import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    card_holder_name: yup.string().required(),
    card_number: yup.number().positive().max(16).integer().required(),
    card_cvv: yup.number().positive().max(3).integer().required(),
    expire_month: yup.number().positive().integer().required(),
    expire_year: yup.number().positive().integer().required(),
  })
  .required();

const CreditCardForm = ({ lead, onSelectedOption, prorated }) => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);
  // console.table(lead);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) return navigate(`/payment/${lead?._id}/success`);
  }, [success, navigate, lead?.paymentMethod]);

  const totalOnetimeFees = lead?.onetime_fees?.reduce(
    (acc, curr) => (acc += parseFloat(curr.feeAmount)),
    0
  );

  const handleSave = async (data) => {
    try {
      setIsloading(true);

      const response = await axios.post(
        "https://api.stripe.com/v1/tokens",
        {
          "card[number]": data.card_number,
          "card[name]": data.card_holder_name,
          "card[exp_month]": data.expire_month,
          "card[exp_year]": data.expire_year,
          "card[cvc]": data.card_cvv,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_SK}`,
          },
        }
      );

      const subscription = await axios.post(
        process.env.REACT_APP_API_URL + "stripes/cards",
        {
          name: data.card_holder_name,
          lead_name: lead?.firstname + " " + lead?.lastname,
          email: lead?.email,
          token: response.data.id,
          priceID: lead?.package?.price_id,
          selected_method: lead?.paymentMethod,
          one_time_fees: totalOnetimeFees,
          contract_length: lead?.contract_length,
          recurring_date: lead?.billing_date,
          amount: lead?.package?.amount,
          lead: lead?._id,
          prorated,
          manager: {
            name: lead?.manager?.firstname + " " + lead?.manager?.lastname,
            email: lead?.manager?.email,
          },
        },
        {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      );

      if (lead?.paymentMethod === "card") {
        const client = await axios.post(
          process.env.REACT_APP_API_URL + "clients",
          {
            status: "Pending agreement",
            lead: lead?._id,
            manager: lead?.manager?._id,
            initial_payment:
              Number(prorated) > 0
                ? prorated + totalOnetimeFees
                : totalOnetimeFees + lead?.package?.amount,
          }
        );

        await axios.post(process.env.REACT_APP_API_URL + "customers", {
          customer_id: subscription?.data?.data.id,
          client: client?.data?.data?._id,
        });
      }

      toast.success("Card submitted successfully");

      if (lead?.paymentMethod !== "card") {
        onSelectedOption();
      } else {
        setSuccess(true);
      }

      return true;
    } catch (error) {
      let msg =
        error?.response?.data?.error?.message?.toString() ||
        error?.response?.data?.message?.toString() ||
        error?.response?.data?.error?.toString();
      toast.error(msg);
    } finally {
      setIsloading(false);
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
    const keyCode = e.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      e.preventDefault();
    }
  };

  const handleText = (event) => {
    if (
      !(
        (
          event.keyCode === 8 || // Backspace
          event.keyCode === 46 || // Delete
          (event.keyCode >= 65 && event.keyCode <= 90) || // Uppercase alphabets
          (event.keyCode >= 97 && event.keyCode <= 122) || // Lowercase alphabets
          event.keyCode === 32
        ) // Numpad keys
      ) || // Spacebar
      (event.keyCode >= 96 && event.keyCode <= 105)
    ) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="rounded-md w-full p-6 mx-auto lg:w-2/3">
          <div>
            <label className="mb-3 block text-base font-medium text-[#07074D]">
              Card Number
            </label>
            <input
              placeholder="Card Number"
              onKeyDown={preventMinus}
              maxLength={16}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
              {...register("card_number", { required: true })}
            />
            {errors.card_number && (
              <span className="text-red-600">Card Number is required</span>
            )}
          </div>
          <div className="my-3">
            <label className="mb-3 block text-base font-medium text-[#07074D]">
              Card Holder Name
            </label>
            <input
              type="text"
              placeholder="Card Holder Name"
              onKeyDown={handleText}
              {...register("card_holder_name", { required: true })}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
            />
            {errors.card_holder_name && (
              <span className="text-red-600">Card Holder name is required</span>
            )}
          </div>
          <div className="flex flex-col gap-3 sm:flex-row">
            <div className="my-3 md:w-1/3">
              <label className="mb-3 block text-base font-medium text-[#07074D]">
                CVV
              </label>
              <input
                placeholder="Cvv number"
                onKeyDown={preventMinus}
                maxLength={3}
                {...register("card_cvv", { required: true })}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
              />
              {errors.card_cvv && (
                <span className="text-red-600">Card CVV is required</span>
              )}
            </div>
            <div className="my-3 md:w-1/3">
              <label className="mb-3 block text-base font-medium text-[#07074D]">
                Expiry Month
              </label>
              <input
                placeholder="MM"
                onKeyDown={preventMinus}
                maxLength={2}
                min={1}
                max={12}
                {...register("expire_month", { required: true })}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
              />
              {errors.expire_month && (
                <span className="text-red-600">
                  Expiration month is required
                </span>
              )}
            </div>
            <div className="my-3 md:w-1/3">
              <label className="mb-3 block text-base font-medium text-[#07074D]">
                Expiration Year
              </label>
              <input
                placeholder="YYYY"
                maxLength={4}
                onKeyDown={preventMinus}
                {...register("expire_year", { required: true })}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#003049] focus:shadow-md"
              />
              {errors.expire_year && (
                <span className="text-red-600">
                  Expiration Year is required
                </span>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <span className="text-red-500 my-4">
              NB: State and Local taxes applicable (exclusive of original
              amount)
            </span>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              value={"agreed"}
              className="form-checkbox h-5 w-5 text-red-600 mr-2"
              {...register("agreed", {
                required: true,
              })}
            />
            <span className="text-gray-500 my-4">
              Accept terms and conditions
            </span>
          </div>
          {errors.agreed && (
            <span className="text-red-600">
              You must agree to the terms and conditions
            </span>
          )}
        </div>
        <div className="my-4 mx-auto lg:w-2/3 flex justify-between">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="block text-center uppercase bg-white hover:bg-white focus:shadow-outline focus:outline-none text-[#0E669B] text-xs py-3 px-10 rounded"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="block text-center uppercase shadow bg-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
          >
            {isLoading ? "loading..." : "Pay"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreditCardForm;
