import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Signature from "../../../components/Signature";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import {
  getAddendum,
  reset,
  signAddendum,
} from "../../../redux/addendum/addendum.slice";

const ContractAmendmentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const sigCanvas = useRef({});

  const [isOpen, setIsOpen] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [term, setTerm] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const { addendum, isLoading, isSuccess, isError, error } = useSelector(
    (state) => state.addendum
  );

  // Validation function
  const validateForm = () => {
    const errors = {};

    if (!term && !addendum?.term) {
      errors.term = "Please select a service term";
    }

    if (!imageURL && !addendum?.signature) {
      errors.signature = "Please provide your signature";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Effect for handling success state
  useEffect(() => {
    if (isSuccess) {
      toast.success("Addendum signed successfully");
      dispatch(reset());
      navigate(`/contract/${addendum?.client?._id}/success`);
    }
  }, [isSuccess, navigate, dispatch, addendum?.client?._id]);

  // Effect for handling error state
  useEffect(() => {
    if (isError && error) {
      toast.error(error);
      setIsSubmitting(false);
    }
  }, [isError, error]);

  // Effect for fetching addendum data
  useEffect(() => {
    dispatch(getAddendum(id));
  }, [dispatch, id]);

  // Effect for initializing term state from addendum
  useEffect(() => {
    if (addendum?.term) {
      setTerm(addendum.term);
    }
  }, [addendum?.term]);

  const handleTermChange = (e) => {
    setTerm(e.target.value);
    setValidationErrors((prev) => ({ ...prev, term: null }));
  };

  const toggle = () => {
    if (addendum?.status !== "Signed") {
      setIsOpen(!isOpen);
    }
  };

  const clear = () => sigCanvas.current.clear();

  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setValidationErrors((prev) => ({ ...prev, signature: null }));
    setIsOpen(false);
  };

  const handleSave = async () => {
    try {
      if (!validateForm()) {
        // Show all validation errors
        Object.entries(validationErrors).forEach(([key, value]) => {
          if (value) toast.error(value);
        });
        return;
      }

      setIsSubmitting(true);
      dispatch(
        signAddendum({
          signature: imageURL,
          id: addendum?._id,
          term: term || addendum?.term,
        })
      );
    } catch (error) {
      toast.error(error.message);
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    if (
      window.confirm(
        "Are you sure you want to cancel? All changes will be lost."
      )
    ) {
      navigate("/");
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="max-w-2xl mx-auto bg-white shadow-lg rounded p-8 border border-gray-200">
      <div className="text-center mb-6">
        <h1 className="text-xl font-bold">
          Amendment to Savvy Surveillance Customer Agreement
        </h1>
      </div>

      <div className="space-y-6">
        <div className="flex items-baseline">
          <span>
            As of{" "}
            {addendum?.signed_date
              ? moment(addendum?.signed_date).format("MMMM Do, YYYY")
              : moment().format("MMMM Do, YYYY")}{" "}
            the contract entitled Savvy Surveillance Customer
          </span>
        </div>
        <span>Agreement between the following parties:</span>

        <div className="space-y-1">
          <p>Savvy Surveillance</p>
          <div className="border-b-2 border-gray-300 pb-1">
            <span className="text-gray-500">
              {addendum?.firstname} {addendum?.lastname}
            </span>
          </div>
        </div>

        <p>The following amendments will be made to the original agreement:</p>

        <div className="space-y-4">
          <div>
            <p className="font-semibold">SERVICE CHARGES:</p>
            <div className="flex items-baseline gap-1">
              <span>
                Client agrees to pay each month the $
                {addendum?.renewal_amount?.toFixed(2)} service rate and any
                applicable tax.
              </span>
            </div>
          </div>

          <div>
            <p className="font-semibold mb-2">SERVICE TERM:</p>
            <div className="space-y-2">
              <div
                className={`space-y-2 ${
                  validationErrors.term
                    ? "border-red-500 border rounded p-2"
                    : ""
                }`}
              >
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="term"
                    value="initial"
                    checked={term === "initial"}
                    onChange={handleTermChange}
                    disabled={true}
                    className="h-4 w-4 border-gray-300 rounded"
                  />
                  <span>Client initial term</span>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="term"
                    value="renewal"
                    checked={term === "renewal"}
                    onChange={handleTermChange}
                    disabled={true}
                    className="h-4 w-4 border-gray-300 rounded"
                  />
                  <span>
                    Client agrees to a renewal term of{" "}
                    {addendum?.renewal_months}
                  </span>
                  <span>months</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="text-sm">
          These changes are the only changes to the original contract. The
          entire remainder of the original contract remains in full force. This
          Amendment shall be effective once signed by both parties.
        </p>

        <div>
          <p className="mb-4">
            This Amendment shall be signed by the following:
          </p>

          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4 items-baseline">
              <div>
                <div className="flex items-baseline gap-2">
                  <span>By:</span>
                  <div
                    className={`flex-1 border-b-2 ${
                      validationErrors.signature
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  >
                    {imageURL || addendum?.signature ? (
                      <img
                        src={imageURL || addendum?.signature}
                        alt="Signature"
                        className="p-3 h-20 w-60 cursor-pointer"
                        onClick={
                          addendum?.status !== "Signed" ? toggle : undefined
                        }
                      />
                    ) : (
                      <div
                        className="h-20 w-60 cursor-pointer"
                        onClick={
                          addendum?.status !== "Signed" ? toggle : undefined
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="text-gray-500 text-sm mt-1">
                  {addendum?.firstname} {addendum?.lastname}
                </div>
              </div>
              <div>
                <div className="flex items-baseline gap-2">
                  <span>Date:</span>
                  <div className="flex-1 border-b-2 border-gray-300">
                    <span className="text-gray-500">
                      {addendum?.signed_date
                        ? moment(addendum?.signed_date).format("MM/DD/YYYY")
                        : moment().format("MM/DD/YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 items-baseline">
              <div>
                <div className="flex items-baseline gap-2">
                  <span>By:</span>
                  <div className="flex-1 border-b-2 border-gray-300">
                    <img
                      src={addendum?.lead?.manager?.signature}
                      alt="Manager Signature"
                      className="p-3 h-20 w-60"
                    />
                  </div>
                </div>
                <div className="text-gray-500 text-sm mt-1">
                  {addendum?.lead?.manager?.firstname}{" "}
                  {addendum?.lead?.manager?.lastname}
                </div>
              </div>
              <div>
                <div className="flex items-baseline gap-2">
                  <span>Date:</span>
                  <div className="flex-1 border-b-2 border-gray-300">
                    <span className="text-gray-500">
                      {addendum?.signed_date
                        ? moment(addendum?.signed_date).format("MM/DD/YYYY")
                        : moment().format("MM/DD/YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {addendum?.status !== "Signed" && (
        <div className="my-10 mx-auto flex justify-between">
          <button
            onClick={handleCancel}
            className="block text-center bg-white hover:bg-gray-100 focus:shadow-outline focus:outline-none text-[#0E669B] text-xs py-3 px-10 rounded transition duration-150"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={isLoading || isSubmitting}
            className="block text-center shadow bg-green-900 hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded transition duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? "Signing..." : "Agree and Submit"}
          </button>
        </div>
      )}

      <Signature
        toggle={toggle}
        isOpen={isOpen}
        sigCanvas={sigCanvas}
        imageURL={imageURL}
        clear={clear}
        save={save}
      />
    </div>
  );
};

export default ContractAmendmentForm;
