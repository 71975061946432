import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLead } from "../../redux/leads/lead.slices";
import moment from "moment";
import useProratedCharges from "../../hooks/useProratedCharges";
import Loader from "../../components/Loader";

export default function Home() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading, lead } = useSelector((state) => state.leads);

  React.useEffect(() => {
    dispatch(getLead(id));
  }, [dispatch, id]);

  const { proratedCharges, totalCharges } = useProratedCharges(lead);

  React.useEffect(() => {
    if (lead?.status === "Paid") {
      navigate(`/payment/${lead?._id}/completed`);
    } else if (lead?.status === "ACH Payment Awaiting") {
      navigate(`/payment/${lead?._id}`);
    }
  }, [lead?._id, lead?.status, navigate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="border min-h-1/2 w-screen rounded-md lg:w-1/2 mx-auto m-10">
      <div className="h-40 flex flex-col justify-center items-center rounded-t-md bg-gradient-to-r from-[#021521] to-[#083757]">
        <h1 className="text-white text-center px-4">
          Savvy Surveillance customer payment details
        </h1>
      </div>
      <div className="p-6 flex flex-col justify-center">
        <div className="mx-auto">
          <p className="text-gray-500 my-4">
            Client name:{" "}
            <span className="text-black">
              {lead?.firstname} {lead?.lastname}
            </span>
          </p>
          <p className="text-gray-500 my-4">
            Email:{" "}
            <span className="text-black">{lead?.email || lead?.phone}</span>
          </p>
          <p className="text-gray-500 my-4">
            Contact: <span className="text-black">{lead?.phone}</span>
          </p>
          <p className="text-gray-500 my-4">
            Date Of Birth:{" "}
            <span className="text-black">
              {moment(lead?.dob).format("YYYY/MM/DD")}
            </span>
          </p>
          <p className="text-gray-500 my-4">
            Address:{" "}
            <span className="text-black">
              {lead?.street}, {lead?.state}, {lead?.zipcode}, {lead?.city}
            </span>
          </p>
        </div>
        <div className="border rounded-md w-full p-6 mx-auto lg:w-2/3">
          <div className="flex justify-between">
            <h2 className="text-gray-500">Item</h2>
            <h2 className="text-gray-500">Amount</h2>
          </div>

          <div className="flex justify-between mt-5">
            <h2 className="text-black">One Time Fees Total</h2>
            <h2 className="text-black">
              $
              {lead?.onetime_fees
                ?.reduce((acc, fee) => acc + parseFloat(fee.feeAmount || 0), 0)
                .toFixed(2)}
            </h2>
          </div>
          <hr className="my-3" />
          <div className="flex justify-between mt-5">
            <h2 className="text-black">
              {lead?.package?.name} <br />{" "}
              <span className="text-gray-500 text-sm">Recurring Charge</span>
            </h2>
            <h2 className="text-black">${lead?.package?.amount.toFixed(2)}</h2>
          </div>
          <hr className="my-3" />
          <div className="flex justify-between">
            <h2 className="text-black">Prorated Amount</h2>
            <h2 className="text-red-700">USD${proratedCharges.toFixed(2)}</h2>
          </div>
          <hr className="my-3" />
          <div className="flex justify-between">
            <h2 className="text-black">Current Charges</h2>
            <h2 className="text-red-700">USD${totalCharges.toFixed(2)}</h2>
          </div>

          <div className="flex items-center">
            <span className="text-red-500 my-4">
              NB: State and Local taxes applicable (exclusive of original
              amount)
            </span>
          </div>
        </div>

        <div className="w-full p-6 mx-auto lg:w-2/3">
          <Link
            to={`/payment/${lead?._id}`}
            className="block my-4 text-center mx-auto shadow bg-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
          >
            Next
          </Link>
        </div>
      </div>
    </section>
  );
}
