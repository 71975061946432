import { configureStore } from "@reduxjs/toolkit";
import clientSlices from "./clients/client.slices";
import leadSlice from "./leads/lead.slices";
import addendumeSlice from "./addendum/addendum.slice";

const store = configureStore({
  reducer: {
    leads: leadSlice,
    clients: clientSlices,
    addendum: addendumeSlice,
  },
});

export default store;
